import type { FC } from 'react';
import React from 'react';
import type { MenuMode } from 'rc-menu/lib/interface';
import Link from 'next/link';
import { Menu } from 'antd';
import { useTranslation } from 'next-i18next';

interface Props {
  mode?: MenuMode;
  className: string;
  onClick?: () => void;
}

export const MenuContent: FC<Props> = ({ mode, className, onClick }) => {
  const { t } = useTranslation('menu');

  return (
    <Menu
      className={className}
      mode={mode}
      style={{ width: '100%', background: 'none' }}
    >
      <Menu.Item key="dashboard" onClick={onClick} data-cy="dashboard-nav-bar">
        <Link href="/dashboard">{t('Dashboard')}</Link>
      </Menu.Item>
      <Menu.Item key="articles" onClick={onClick} data-cy="menu-item-articles">
        <Link href="/articles">{t('Articles')}</Link>
      </Menu.Item>
      <Menu.Item key="companies" onClick={onClick}>
        <Link href="/companies">{t('Companies')}</Link>
      </Menu.Item>
      <Menu.Item key="alerts" onClick={onClick}>
        <Link href="/alerts">{t('Alerts')}</Link>
      </Menu.Item>
      <Menu.Item key="binders" onClick={onClick}>
        <Link href="/binders">{t('Binders')}</Link>
      </Menu.Item>
      <Menu.Item key="portfolios" onClick={onClick}>
        <Link href="/portfolios">{t('Portfolios')}</Link>
      </Menu.Item>
      {/*<Menu.Item key="stats" onClick={onClick}>*/}
      {/*  <Link href="/stats">{t('Analytics')}</Link>*/}
      {/*</Menu.Item>*/}
    </Menu>
  );
};
