import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebouncedState, useWindowSize } from '@react-hookz/web';
import { Empty, Select } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { KeyboardEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { Loader } from 'src/components/Loader';
import useSearch from 'src/components/SearchResults/useSearch';
import { TAB_SIZE } from 'src/util/globals';

import { useAuth } from '../util/use-auth';
import { useLayout } from '../util/use-layout';
import UserAvatar from './Avatar';
import { MenuContent } from './Menu';
import { RenderTopBar } from './RenderTopBar';

const Topbar = () => {
  const { user } = useAuth();
  const router = useRouter();
  const { setCollapsedMenu } = useLayout();
  const size = useWindowSize();
  const { t } = useTranslation('account');
  const [onDropdown, setOnDropdown] = useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useDebouncedState<string | undefined>(
    undefined,
    1000
  );
  const [inputQuery, setInputQuery] = useState<string | undefined>(undefined);
  const { isLoading, companiesData, portfolioData, binderData } =
    useSearch(searchQuery);

  /**** Redirect to Result page on Press Enter ****/
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      router.push(`/search-results?q=${inputQuery}`);
      setIsSelectOpen(false);
      setSearchQuery(undefined); // Reset the search query
      setInputQuery(undefined); // Reset the input query
    }
  };

  const getTopBar = useCallback(() => {
    return (
      <RenderTopBar
        companiesData={companiesData}
        portfolioData={portfolioData}
        binderData={binderData}
        searchQuery={searchQuery}
        setIsSelectOpen={setIsSelectOpen}
        setOnDropdown={setOnDropdown}
      />
    );
  }, [binderData, companiesData, portfolioData, searchQuery]);

  return (
    <div className="gx-header-horizontal">
      <header className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            {size.width && size.width < TAB_SIZE ? (
              <div className="gx-linebar gx-mr-5 gx-mb-2">
                <MenuOutlined
                  onClick={() => setCollapsedMenu(true)}
                  className={`gx-icon-btn icon icon-menu-fold`}
                  data-cy="hamburger-menu"
                />
              </div>
            ) : null}
            {/* @ts-ignore*/}
            {size.width && size.width < TAB_SIZE ? (
              <div style={{ width: 120, height: 80, position: 'relative' }}>
                <Link href="/" legacyBehavior>
                  <Image
                    alt=""
                    layout={'fill'}
                    objectFit={'contain'}
                    src={
                      user && user.active_profile?.logo
                        ? user.active_profile.logo
                        : '/images/logo-blue.png'
                    }
                  />
                </Link>
              </div>
            ) : (
              <div style={{ width: 120, height: 80, position: 'relative' }}>
                <Link href="/" legacyBehavior>
                  <Image
                    alt=""
                    layout={'fill'}
                    objectFit={'contain'}
                    src={
                      user && user.active_profile?.logo
                        ? user.active_profile.logo
                        : '/images/logo-blue.png'
                    }
                  />
                </Link>
              </div>
            )}
            {size.width && size.width >= TAB_SIZE && (
              <div
                style={{
                  width: 360,
                  height: 32,
                  marginLeft: '120px',
                  marginTop: '5px',
                }}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  value={searchQuery}
                  onKeyUp={(e) => handleKeyDown(e)}
                  placeholder={t('Search')}
                  open={isSelectOpen}
                  defaultOpen={false}
                  onFocus={() => setSearchQuery(undefined)}
                  onBlur={() => {
                    if (onDropdown) {
                      return;
                    }

                    setSearchQuery(undefined);

                    setTimeout(() => {
                      setIsSelectOpen(false);
                    }, 500);
                  }}
                  onSearch={(value: string) => {
                    setInputQuery(value);
                    if (value.length >= 3) {
                      setIsSelectOpen(true);
                      setSearchQuery(value);
                    }
                  }}
                  suffixIcon={
                    <SearchOutlined
                      onClick={() => {
                        if (searchQuery && searchQuery.length > 3) {
                          router.push(`/search-results?q=${searchQuery}`);
                          setIsSelectOpen(false);
                          setSearchQuery(undefined); // Reset the search query
                          setInputQuery(undefined); // Reset the input query
                        }
                      }}
                    />
                  }
                  dropdownRender={() => {
                    if (
                      !isLoading &&
                      !companiesData?.results?.length &&
                      !portfolioData?.results?.length &&
                      !binderData?.results?.length
                    )
                      return (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '50%',
                            alignItems: 'center',
                          }}
                          description={t('No Data')}
                        />
                      );
                    return <Loader loading={isLoading}>{getTopBar()}</Loader>;
                  }}
                  style={{ width: 360 }}
                />
              </div>
            )}
            {size.width && size.width >= TAB_SIZE && (
              <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-user-nav" data-cy="user-avatar">
                  <UserAvatar />
                </li>
              </ul>
            )}
          </div>
        </div>
      </header>
      {size.width && size.width >= TAB_SIZE && (
        <div
          className="gx-header-horizontal-nav gx-header-horizontal-nav-curver"
          style={{
            backgroundColor: user?.active_profile?.primary_color || undefined,
          }}
        >
          <div className="gx-container">
            <div className="gx-header-horizontal-nav-flex">
              <MenuContent className="navigation-bar" mode="horizontal" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Topbar;
