import moment from 'moment';

export enum QUERIES {
  PUBLISHED_ARTICLES = 'PUBLISHED_ARTICLES',
  INDUSTRY_ARTICLE_OVERVIEW = 'INDUSTRY_ARTICLE_OVERVIEW',
  PUBLISHED_ARTICLES_COMPANY = 'PUBLISHED_ARTICLES_COMPANY',
  CATEGORY_ARTICLE_OVERVIEW_COMPANY = 'CATEGORY_ARTICLE_OVERVIEW_COMPANY',
  TRENDING_TERMS_COMPANY = 'TRENDING_TERMS_COMPANY',
  ARTICLES_HISTORY_COMPANY = 'ARTICLES_HISTORY_COMPANY',
  CATEGORIES = 'CATEGORIES',
  ARTICLES_SUMMARY = 'ARTICLES_SUMMARY',
  ARTICLES = 'ARTICLES',
  LATEST_ARTICLES = 'LATEST_ARTICLES',
  PORTFOLIOS = 'PORTFOLIOS',
  PORTFOLIO = 'PORTFOLIO',
  PORTFOLIO_GROUPS = 'PORTFOLIO_GROUPS',
  PORTFOLIO_USERS = 'PORTFOLIO_USERS',
  GROUPS = 'GROUPS',
  PROFILES = 'PROFILES',
  COMPANIES = 'COMPANIES',
  COMPANY = 'COMPANY',
  MISSING_COMPANY_INVESTIGATION = 'MISSING_COMPANY_INVESTIGATION',
  BATCH_REGISTRATION = 'BATCH_REGISTRATION',
  ACTIVE_COUNTRIES = 'ACTIVE_COUNTRIES',
  DIRECTORS = 'DIRECTORS',
  REGISTRATION_COMPANIES = 'REGISTRATION_COMPANIES',
  COMPANY_MATRIX_DATA = 'COMPANY_MATRIX_DATA',
  CLOUD = 'COULD',
  INDUSTRY_MATRIX_DATA = 'INDUSTRY_MATRIX_DATA',
  MAP_DATA = 'MAP_DATA',
  WORD_CLOUD = 'WORD_CLOUD',
  USER_DETAILS = 'USER_DETAILS',
  WEBSOCKET_SESSION = 'WEBSOCKET_SESSION',
  SAVED_ARTICLE_FILTERS = 'SAVED_ARTICLE_FILTERS',
  ADD_COMPANIES = 'ADD_COMPANIES',
  REVIEWS = 'REVIEWS',
  REPORTS = 'REPORTS',
  PUBLICATIONS = 'PUBLICATIONS',
  ALERTS = 'ALERTS',
  USERS = 'USERS',
  INVITATIONS = 'INVITATIONS',
  GROUP_USERS = 'GROUP_USERS',
  BINDERS = 'BINDERS',
  BINDER_TAGS = 'BINDER_TAGS',
  BINDER_DATA = 'BINDER_DATA',
  BINDER_ITEM = 'BINDER_ITEM',
  BINDER_GROUPS = 'BINDER_GROUPS',
  BINDER_USERS = 'BINDER_USERS',
  BINDER_ITEM_NOTES = 'BINDER_ITEM_NOTES',
  BINDER_NOTES = 'BINDER_NOTES',
  BINDER_SUMMARY = 'BINDER_SUMMARY',
  PUBLICATION_TYPES = 'PUBLICATION_TYPES',
  CATEGORIES_FLAT = 'CATEGORIES_FLAT',
  CATEGORIES_TREE = 'CATEGORIES_TREE',
  CATEGORY_SETS = 'CATEGORY_SETS',
  URL = 'URL',
  REGISTRATION_DETAILS = 'REGISTRATION_DETAILS',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  REGISTRATION_COMPANY_NAME = 'REGISTRATION_COMPANY_NAME',
  REGISTRATION_REQUEST = 'REGISTRATION_REQUEST',
  SCREENING_RESULTS = 'SCREENING_RESULTS',
  OWNERSHIP_LIST = 'OWNERSHIP_LIST',
  BILLING_STATISTICS = 'BILLING_STATISTICS',
  BILLING_DAILY_ANALYTICS_LIST = 'BILLING_DAILY_ANALYTICS_LIST',
  NOTIFICATIONS_LIST = 'NOTIFICATIONS_LIST',
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const INTERVAL = ['hour', 'day', 'month', 'week', 'year'] as const;

export const DASHBOARD_TIME_RANGES = {
  tomorrow: moment().add(1, 'day').endOf('day').format(DATE_FORMAT),
  yesterday: moment().startOf('day').format(DATE_FORMAT),
  lastWeek: moment().subtract(1, 'week').startOf('day').format(DATE_FORMAT),
  lastMonth: moment().subtract(1, 'month').startOf('day').format(DATE_FORMAT),
  lastYear: moment().subtract(1, 'year').startOf('day').format(DATE_FORMAT),
};

export const TAB_SIZE = 992;

// Refetch registration details every 2 seconds.
export const REFETCH_INTERVAL_REGISTRATION_UPDATES = 2000;
