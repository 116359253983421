import React, { useEffect } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { useRouter } from 'next/router';
import moment from 'moment';
import { getCookie } from 'cookies-next';
import Bugsnag from '@bugsnag/js';
import antLocaleNL from 'antd/lib/locale/nl_NL';
import antLocaleDE from 'antd/lib/locale/de_DE';
import antLocaleFR from 'antd/lib/locale/fr_FR';
import antLocaleJP from 'antd/lib/locale/ja_JP';
import antLocaleKO from 'antd/lib/locale/ko_KR';
import antLocaleZH from 'antd/lib/locale/zh_CN';
import antLocalePT from 'antd/lib/locale/pt_PT';
import antLocaleEN from 'antd/lib/locale/en_US';
import { useTranslation } from 'next-i18next';
import Intercom from '@intercom/messenger-js-sdk';

import { useAPIContext } from 'src/util/context/APIContext';

import { useAxios } from '../util/use-api';
import { useWebsocket } from '../util/use-websocket';
import Topbar from './Topbar';
import SideBar from './Sidebar';
import FullScreenLoader from '../components/Loader';
import { isUnRestrictedRoute, useAuth } from '../util/use-auth';

const getLocale = (code: string) => {
  switch (code) {
    case 'de':
      return antLocaleDE;
    case 'nl':
      return antLocaleNL;
    case 'fr':
      return antLocaleFR;
    case 'ja':
      return antLocaleJP;
    case 'ko':
      return antLocaleKO;
    case 'pt':
      return antLocalePT;
    case 'zh':
      return antLocaleZH;
    default:
      return antLocaleEN;
  }
};

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const { isLoggedIn, localeChecked, user } = useAuth();
  const { API } = useAPIContext();
  const { sendToWebsocket } = useWebsocket();
  const [lastPageVisited, setLastPageVisited] = React.useState<string | null>(
    null
  );

  // Set moment locale
  moment.locale(router.locale as string);

  /**
   * Automatically redirect the user to the correct locale if not already using an locale, so for example when
   * the user is visiting without specifying a locale it will use the user specific locale.
   */
  useEffect(() => {
    if (user && process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
      Bugsnag.setUser(
        user.external_id,
        user.username,
        `${user.first_name} ${user.last_name}`
      );
    }
  }, [router, router.defaultLocale, router.locale, user, i18n.options]);

  useEffect(() => {
    if (user && process.env.NEXT_PUBLIC_INTERCOM_APP_ID) {
      Intercom({
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        region: 'eu',
        name: `${user.first_name} ${user.last_name}`,
        email: user.username,
        user_hash: user.intercom_user_hash,
        company: {
          id: user.active_profile?.external_id,
          name: user.active_profile?.name,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (router.pathname === '/articles/[article_id]') {
      return;
    }
    if (user?.external_id && lastPageVisited !== window.location.href) {
      API?.profilesAPI.profilesAnalyticsPageVisitsCreate({
        pageVisitRequest: {
          current_page_name: router.pathname,
          parameters: router.query,
        },
      });

      console.log('Sending to websocket', router.query);
      sendToWebsocket({
        ...router.query,
      });

      setLastPageVisited(window.location.href);
    }
  }, [
    router.query,
    router.pathname,
    API?.profilesAPI,
    user?.external_id,
    lastPageVisited,
  ]);

  if (
    !isUnRestrictedRoute(router.pathname) &&
    (!isLoggedIn || !user || !localeChecked)
  ) {
    return <FullScreenLoader isMain={true} />;
  }

  // if (!connected) {
  //   return <FullScreenLoader isMain={true} />;
  // }

  if (isUnRestrictedRoute(router.pathname) || !user?.active_profile) {
    return (
      <Layout
        className={`gx-app-layout full-scroll horizontal-layout full-layout`}
      >
        <Layout>
          <Layout.Content className={`gx-layout-content gx-container-wrap`}>
            <div className="gx-main-content-wrapper">
              <ConfigProvider locale={getLocale(router.locale as string)}>
                {children}
              </ConfigProvider>
            </div>
            {/* <BG className="-mb-1 z-10" /> */}
            <Layout.Footer>
              <div className="gx-layout-footer-content">
                ©{moment().year()} Business Radar
              </div>
            </Layout.Footer>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  } else {
    if (!user) {
      return <FullScreenLoader isMain={true} />;
    }
    return (
      <Layout
        className={`gx-app-layout full-scroll horizontal-layout full-layout`}
      >
        <SideBar />
        <Layout>
          <Topbar />
          <Layout.Content className={`gx-layout-content gx-container-wrap`}>
            <div className="gx-main-content-wrapper full-layout">
              <ConfigProvider locale={getLocale(router.locale as string)}>
                {children}
              </ConfigProvider>
            </div>
            <Layout.Footer style={{ marginTop: '15px' }}>
              <div className="gx-layout-footer-content">
                ©{moment().year()} Business Radar
              </div>
            </Layout.Footer>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
};

// @ts-ignore
export default AppLayout;
