import type { Moment } from 'moment';
import moment from 'moment';

export const formatDateTime = (date: string | Moment | null | undefined) => {
  if (!date) return '';

  let _date: string | Moment = moment.utc(date).local();
  if (moment().diff(date, 'days') < 1) {
    _date = _date.fromNow();
  } else {
    _date =
      _date !== _date.startOf('day')
        ? _date.format('dddd DD MMMM, YYYY HH:mm')
        : _date.format('dddd DD MMMM, YYYY');
  }

  return _date;
};
