import dynamic from 'next/dynamic';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import 'quill-mention/dist/quill.mention.css'; // Import mention styles
import { useEffect, useRef, useState } from 'react';

import { useAPIContext } from 'src/util/context/APIContext';

const QuillEditor = dynamic(() => import('react-quill'), { ssr: false });

const BinderNoteTextEditor = ({
  value = '',
  onChange,
}: {
  value?: string;
  onChange?: (e: string) => void;
}) => {
  const editorRef = useRef(null);
  const [modules, setModules] = useState(null);
  const { API } = useAPIContext();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const Quill = require('react-quill').Quill;

      // Register quill-mention
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const mention = require('quill-mention').default;
      Quill.register('modules/mention', mention);

      setModules({
        // @ts-ignore
        toolbar: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
          [{ align: [] }],
          [{ color: [] }],
          ['code-block'],
          ['clean'],
        ],
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@', '#'],
          // @ts-ignore
          source: async function (searchTerm, renderList, mentionChar) {
            try {
              const response = await API?.profilesAPI.profilesUsersList({
                query: searchTerm,
              });

              const suggestions = response?.data.results?.map((item) => ({
                id: item.user.username, // Use `id` as the identifier for the mention
                value: `${item.user.first_name} ${item.user.last_name}`, // Full name shown in dropdown
                username: item.user.username, // Additional data, like username
              }));

              renderList(suggestions); // Pass suggestions to renderList to display
            } catch (error) {
              console.error('Error fetching mentions: ', error);
              renderList([]); // Return an empty list if there's an error
            }
          },
        },
      });
    }
  }, [API]);

  const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'align',
    'color',
    'code-block',
    'mention',
  ];

  if (!modules) {
    return <div>Loading...</div>;
  }

  return (
    <QuillEditor
      // @ts-ignore
      ref={editorRef}
      value={value}
      onChange={(content) => (onChange ? onChange(content) : null)}
      modules={modules}
      formats={quillFormats}
      className="w-full h-[100%] bg-white mb-20"
      style={{ height: 100 }}
    />
  );
};

export default BinderNoteTextEditor;
