import React, { createContext, useContext, useEffect, useRef } from 'react';

import {
  AlertsApi,
  AnalyticsApi,
  CompaniesApi,
  ComplianceApi,
  DnbApi,
  FeedbackApi,
  NewsApi,
  PortfoliosApi,
  ProfilesApi,
  PublicationsApi,
  ReportsApi,
  ReviewsApi,
  TokenApi,
  BindersApi,
  BillingApi,
} from 'src/api-sdk';
import { useAxios } from 'src/util/use-api';
import { useAuth } from 'src/util/use-auth';

export type APIType = {
  analyticsAPI: AnalyticsApi;
  alertsAPI: AlertsApi;
  portfoliosAPI: PortfoliosApi;
  newsAPI: NewsApi;
  feedbackAPI: FeedbackApi;
  companiesAPI: CompaniesApi;
  dnbAPI: DnbApi;
  profilesAPI: ProfilesApi;
  publicationsAPI: PublicationsApi;
  reportsAPI: ReportsApi;
  reviewsAPI: ReviewsApi;
  tokenAPI: TokenApi;
  bindersAPI: BindersApi;
  complianceAPI: ComplianceApi;
  billingAPI: BillingApi;
} | null;

export type APIContextType = {
  API: APIType;
};

export const APIContext = createContext<APIContextType | null>(null);

export const useAPIContext = (): APIContextType => {
  const apiContext = useContext(APIContext);

  if (!apiContext) {
    throw new Error('apiContext must be used within a APIContextProvider');
  }

  return apiContext;
};

export const APIContextProvider: React.FC = ({ children }) => {
  const { getConfiguration, refreshToken, apiKey } = useAuth();
  const { api } = useAxios();
  const APIRef = useRef<APIType>(null);

  useEffect(() => {
    if (refreshToken || apiKey) {
      APIRef.current = {
        analyticsAPI: new AnalyticsApi(getConfiguration(), undefined, api),
        alertsAPI: new AlertsApi(getConfiguration(), undefined, api),
        portfoliosAPI: new PortfoliosApi(getConfiguration(), undefined, api),
        newsAPI: new NewsApi(getConfiguration(), undefined, api),
        feedbackAPI: new FeedbackApi(getConfiguration(), undefined, api),
        companiesAPI: new CompaniesApi(getConfiguration(), undefined, api),
        dnbAPI: new DnbApi(getConfiguration(), undefined, api),
        profilesAPI: new ProfilesApi(getConfiguration(), undefined, api),
        publicationsAPI: new PublicationsApi(
          getConfiguration(),
          undefined,
          api
        ),
        complianceAPI: new ComplianceApi(getConfiguration(), undefined, api),
        reportsAPI: new ReportsApi(getConfiguration(), undefined, api),
        reviewsAPI: new ReviewsApi(getConfiguration(), undefined, api),
        tokenAPI: new TokenApi(getConfiguration(), undefined, api),
        bindersAPI: new BindersApi(getConfiguration(), undefined, api),
        billingAPI: new BillingApi(getConfiguration(), undefined, api),
      };
    }
  }, [api, getConfiguration, refreshToken, apiKey]);

  return (
    <APIContext.Provider value={{ API: APIRef.current }}>
      {children}
    </APIContext.Provider>
  );
};
