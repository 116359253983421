/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useEffect, useState } from 'react';
import { getCookie, removeCookies, setCookies } from 'cookies-next';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { notification } from 'antd';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'next-i18next';
import moment from 'moment';
// eslint-disable-next-line import/order
import * as momentTZ from 'moment-timezone';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/pt';
import 'moment/locale/zh-cn';

import { hotjar } from 'react-hotjar';

import type { ErrorDescription, UserDetail } from 'src/api-sdk';
import { Configuration, ProfilesApi, TokenApi } from 'src/api-sdk';

import { useAxios } from './use-api';
import { QUERIES } from './globals';

interface AuthContextInterface {
  user: UserDetail | undefined;
  getConfiguration: Function;
  setRefreshToken: Function;
  login: Function;
  logout: Function;
  refresh: Function;
  getUser: Function;
  isLoggedIn: boolean;
  localeChecked: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  originalRequestUrl: string;
  apiKey: string | null;
}

const authContext = React.createContext<AuthContextInterface>(
  {} as ReturnType<typeof useProvideAuth>
);

// Provider component that wraps your app and makes auth object ..
// ... available to any child component that calls useAuth().

export function ProvideAuth({ children }: any): any {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.

export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthContextInterface {
  const router = useRouter();
  const { api, baseApiUrl } = useAxios();
  const { t, i18n } = useTranslation('common');
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [localeChecked, setLocaleChecked] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ErrorDescription | undefined>();
  const [apiKey, setApiKey] = useState<string | null>(() => {
    const savedApiKey = (getCookie('authentication.api_key') as string) || null;
    if (savedApiKey) {
      setIsLoggedIn(true);
      api.defaults.headers.common['Authorization'] = `Token ${savedApiKey}`;
    }
    return savedApiKey;
  });

  const [refreshToken, setRefreshToken] = useState<string | null>(() => {
    return (getCookie('authentication.refresh_token') as string) || null;
  });

  const [originalRequestUrl, setOriginalRequestUrl] = useState<
    string | undefined
  >(undefined);

  /**
   * Get API configuration with settings required.
   */
  const getConfiguration = React.useCallback(() => {
    // if (apiKey != null ){
    //     return new Configuration({
    //         basePath: baseApiUrl,
    //         apiKey: `Token ${apiKey}`
    //     });
    // }
    // if (accessToken != null) {
    //     return new Configuration({
    //         basePath: baseApiUrl,
    //         accessToken: `Bearer ${accessToken}`
    //     });
    // } else {
    return new Configuration({
      basePath: baseApiUrl,
    });
    // }
  }, [baseApiUrl]);

  /**
   * Get user details.
   */
  const getUser = React.useCallback(() => {
    return new Promise<UserDetail>((resolve, reject) => {
      new ProfilesApi(getConfiguration(), undefined, api)
        .profilesUserDetailsRetrieve()
        .then((response) => {
          if (response) {
            resolve(response.data);
          }
        })
        .catch((error) => reject(error));
    });
  }, [api, getConfiguration]);

  const { data: user } = useQuery<UserDetail>([QUERIES.USER_DETAILS], getUser, {
    enabled: isLoggedIn,
  });

  /**
   * Login a user.
   */
  const login = React.useCallback(
    (username: string, password: string) => {
      return new Promise<void>((resolve, reject) => {
        new TokenApi(getConfiguration(), undefined, api)
          .tokenCreate({ tokenObtainPairRequest: { username, password } })
          .then((response) => {
            setRefreshToken(response.data.refresh);
            setAccessToken(response.data.access);
            setIsLoggedIn(true);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [api, getConfiguration]
  );

  /**
   * Refresh Access Token using the stored Refresh Token.
   */
  const refresh = React.useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      if (!refreshToken) {
        return reject(new Error('Refresh token not supplied'));
      }
      new TokenApi(getConfiguration(), undefined, api)
        .tokenRefreshCreate({ tokenRefreshRequest: { refresh: refreshToken } })
        .then((response) => {
          setRefreshToken(response?.data?.refresh);
          setAccessToken(response?.data?.access);
          api.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${response?.data?.access}`;
          setCookies('authentication.refresh_token', response?.data?.refresh);
          setIsLoggedIn(true);
          resolve();
        })
        .catch((error) => {
          setRefreshToken(null);
          setCookies('authentication.refresh_token', null);
          console.log('Refresh failed, so redirecting to login');
          console.log(error);
          if (!isUnRestrictedRoute(router.pathname)) {
            router.push('/login');
          }
          // reject(error);
        });
    });
  }, [api, getConfiguration, refreshToken, router]);

  /**
   * Logout the user and clear the current session.
   */
  const logout = React.useCallback(() => {
    return new Promise<void>((resolve) => {
      setRefreshToken(null);
      setAccessToken(null);
      removeCookies('authentication.refresh_token');
      removeCookies('authentication.api_key');
      setApiKey(null);
      setIsLoggedIn(false);
      setLocaleChecked(false);
      router.push('/login').then();
      resolve();
    });
  }, [router]);

  /// Display API errors as notifications
  React.useEffect(() => {
    if (apiError && apiError.status !== 500 && apiError.title) {
      console.log(apiError);
      notification.error({
        message: apiError.title,
        description: apiError.description,
        duration: 10,
      });
    }
  }, [apiError]);

  /**
   * Custom effect used to reauthenticate and refresh Access Keys using the used Refresh token.
   */
  React.useEffect(() => {
    console.debug('Adding interceptor for keeping track of session.');
    const requestInterceptor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response == undefined) {
          return Promise.reject(error);
        }

        const url = error.request.url || error.request.responseURL;

        console.log('url', url);

        if (
          error.response.data &&
          (error.response.data.code === 'authentication_failed' ||
            error.response.data.code === 'account_inactive' ||
            error.response.data.code === 'user_not_found') &&
          refreshToken
        ) {
          console.log('Incorrect credentials, logging out.');
          logout().then();
          return;
        } else if (
          error.response.data &&
          error.response.data.code === 'no_profile' &&
          refreshToken
        ) {
          console.log('Profile Creation Required.');
          if (!router.pathname.includes('/register/create-profile')) {
            router.push('/register/create-profile');
          }
          return;
        } else if (
          error.response.data &&
          error.response.data.code === 'email_verification_required'
        ) {
          console.log('Email verification required.');
          if (!router.pathname.includes('/register/verification/email')) {
            router.push('/register/verification/email');
          }
          return;
        } else if (
          error.response.data &&
          error.response.data.code === 'phone_verification_required'
        ) {
          console.log('Phone verification required.');
          if (!router.pathname.includes('/register/verification/phone')) {
            router.push('/register/verification/phone');
          }
          return;

          // Handler for when no Access Key is provided, refresh.
        } else if (
          error.response.data &&
          error.response.data.code === 'not_authenticated' &&
          refreshToken
        ) {
          console.log('Access Token not provided, refreshing');

          if (url && url.includes('/token/refresh/')) {
            console.info(
              'Token expired and refresh failed, redirecting to login (not authenticated).'
            );
            logout().then(() => {});
            return Promise.reject(error);
          }

          return refresh().then(() => {
            console.log('Refresh succeeded, continuing request');
            return api.request(error.config);
          });

          // Handler for when the provided Access Key has expired.
        } else if (
          error.response.data &&
          error.response.data.code === 'token_not_valid' &&
          refreshToken
        ) {
          if (url && url.includes('/token/refresh/')) {
            console.info(
              'Token expired and refresh failed, redirecting to login (not valid).'
            );
            logout().then(() => {});
            return Promise.reject(error);
          } else {
            console.info(
              'Token expired, trying to refresh using refresh token'
            );
            return refresh()
              .then(() => {
                console.log(
                  'Token refreshed, retrying request to backend using new Access Token.'
                );
                error.config.headers['Authorization'] =
                  api.defaults.headers.common['Authorization'];
                console.log('config', error.config);
                return api.request(error.config);
              })
              .catch((e) => {
                return Promise.reject(error);
              });
          }
        }

        // Stupid default invalid input errors.
        if (
          error.response.data &&
          error.response.data.description === 'Invalid input.'
        ) {
          error.response.data.title = t('Invalid Input.');
          error.response.data.description = t(
            'Something is incorrectly inputted, check your input and contact support if persistent.'
          );
        }

        const errorDescription: ErrorDescription = error.response.data;
        if (errorDescription && errorDescription.code !== 'invalid') {
          setApiError(errorDescription);
        }

        Bugsnag.notify(error);
        // Promise.reject(error)
        return Promise.reject(error);
      }
    );
    return () => {
      console.debug(
        'Removing authentication interceptor for keeping track of session.'
      );
      api.interceptors.request.eject(requestInterceptor);
    };
  }, [
    t,
    api,
    api.interceptors.response,
    logout,
    refresh,
    refreshToken,
    router,
  ]);

  React.useEffect(() => {
    if (refreshToken) {
      // console.log(`Received refresh ${refreshToken}`)
      setCookies('authentication.refresh_token', refreshToken);
      if (!isLoggedIn) {
        refresh().then();
      }
    } else {
      removeCookies('authentication.refresh_token');
    }
  }, [refreshToken, refresh, isLoggedIn]);

  // Set Original Request URL
  useEffect(() => {
    if (
      !originalRequestUrl &&
      !isUnRestrictedRoute(window.location.pathname) &&
      !isRegistrationRoute(window.location.pathname)
    ) {
      console.log('Setting original request URL', window.location.pathname);
      setOriginalRequestUrl(window.location.pathname);
    }
  }, [setOriginalRequestUrl, originalRequestUrl]);

  // Redirect to create profile if not set
  useEffect(() => {
    if (
      user &&
      !user.active_profile &&
      !isRegistrationRoute(router.pathname) &&
      !isUnRestrictedRoute(router.pathname)
    ) {
      router.push('/register/create-profile/');
    }
  }, [user, router.pathname, router]);

  React.useEffect(() => {
    if (accessToken) {
      // console.log(`Received access ${accessToken}`)
      api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
  }, [accessToken, api.defaults.headers.common]);

  React.useEffect(() => {
    if (apiKey) {
      // console.log(`Received api key ${accessToken}`)
      api.defaults.headers.common['Authorization'] = `Token ${apiKey}`;
      setIsLoggedIn(true);
    }
  }, [apiKey, api.defaults.headers.common]);

  // If query token received from URL parameters, set as access token.
  React.useEffect(() => {
    // console.log(`Received token ${router.query.token}`)
    if (router.query.token) {
      setCookies('authentication.refresh_token', router.query.token as string);
      setRefreshToken(router.query.token as string);
    }
  }, [router.query]);

  // If query api key received from URL parameters, set as access token.
  React.useEffect(() => {
    // console.log(`Received api_key ${router.query.api_key}`)
    if (router.query.api_key) {
      setApiKey(router.query.api_key as string);
      setCookies('authentication.api_key', router.query.api_key as string);
    }
  }, [getUser, router.query]);

  // Set Locale
  useEffect(() => {
    if (user && user.language) {
      moment.locale(user.language ?? 'en');
      momentTZ.locale(user.language ?? 'en');
    }
  }, [user]);

  // Initialize hotjar, do not activate if user is staff or not authenticated.
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_HOTJAR_ID &&
      process.env.NEXT_PUBLIC_HOTJAR_SV &&
      user &&
      !user.is_staff
    ) {
      if (!hotjar.initialized()) {
        hotjar.initialize(
          parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID),
          parseInt(process.env.NEXT_PUBLIC_HOTJAR_SV)
        );
        hotjar.identify(user.external_id, {
          username: user.username,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          language: user.language,
          active_profile: user.active_profile.name,
          is_complete_translation_enabled: user.is_complete_translation_enabled,
          is_compliance_enabled: user.is_compliance_enabled,
        });
      }
    }
  }, [user]);

  // Redirect to login page if not logged in and also not passed credentials.
  useEffect(() => {
    const redirectCheck = setTimeout(() => {
      if (
        !isLoggedIn &&
        !router.query.api_key &&
        !router.query.token &&
        !router.query.disable_sso &&
        !refreshToken &&
        refreshToken !== undefined &&
        !apiKey &&
        apiKey !== undefined &&
        !accessToken &&
        !getCookie('authentication.refresh_token') &&
        !getCookie('authentication.api_key')
      ) {
        // Redirect if custom SSO domain is found
        let ssoDomainName = window.location.host
          .replace('www.', '')
          .split('.')[0];

        // Preview deployment automatic / test automatic sso vertical
        if (
          window.location.host.includes('vercel.app') ||
          ssoDomainName === 'test'
        ) {
          ssoDomainName = 'vertical';
        }

        if (
          (window.location.host.includes('vercel.app') ||
            ssoDomainName === 'test') &&
          process.env.NEXT_PUBLIC_API_URL &&
          process.env.NEXT_PUBLIC_API_URL.includes('api.test.businessradar.com')
        ) {
          if (
            process.env.NEXT_PUBLIC_API_URL &&
            process.env.NEXT_PUBLIC_API_URL.includes(
              'api.test.businessradar.com'
            )
          ) {
            ssoDomainName = 'vertical';
          } else {
            ssoDomainName = '';
          }
        }

        if (
          ssoDomainName != '' &&
          ssoDomainName != 'app' &&
          ssoDomainName != 'test' &&
          ssoDomainName != 'prod' &&
          !ssoDomainName.includes(':') &&
          !ssoDomainName.includes('-') &&
          !window.location.pathname.includes('/articles/') &&
          !window.location.pathname.includes('/alerts/feedback/') &&
          !window.location.host.includes('aig.businessradar') &&
          !router.pathname.includes('/logout')
        ) {
          console.log('Redirecting user to sso login page');
          window.location.assign(
            `${baseApiUrl}/sso/${ssoDomainName}/login/?next_url=${window.location.href}`
          );
        } else if (!isUnRestrictedRoute(router.pathname)) {
          console.log('Redirecting to login page due to not authenticated.');
          router.push('/login').then();
        }
      }
    }, 1000);
    return () => clearTimeout(redirectCheck);
  }, [
    router.query,
    router,
    isLoggedIn,
    refreshToken,
    apiKey,
    accessToken,
    baseApiUrl,
  ]);

  // Redirect to login page if not logged in and also not passed credentials.
  useEffect(() => {
    if (isLoggedIn && isUnauthenticatedRoute(router.pathname)) {
      console.log('Redirecting to original request page due to authenticated.');
      router.push(originalRequestUrl || '/').then();
    }
  }, [router, isLoggedIn, originalRequestUrl]);

  useEffect(() => {
    if (
      !localeChecked ||
      !isLoggedIn ||
      !user ||
      !isUnRestrictedRoute(router.pathname)
    ) {
      if (
        user?.language &&
        router.locale != user.language &&
        // @ts-ignore
        (i18n.options?.locales || []).includes(user.language)
      ) {
        console.log(
          `Locale of session does not match user locale, detected: '${router.locale}' and navigated to '${user.language}'`
        );
        const { pathname, asPath, query } = router;

        router
          .push({ pathname, query }, asPath, { locale: user.language })
          .then();
      }
      setLocaleChecked(true);
    }
  }, [localeChecked, isLoggedIn, i18n.options, router, user]);

  // Return the user object and auth methods
  return {
    user,
    getConfiguration,
    setRefreshToken,
    login,
    logout,
    refresh,
    getUser,
    isLoggedIn,
    localeChecked,
    accessToken,
    refreshToken,
    apiKey,
    originalRequestUrl: originalRequestUrl || '/',
  };
}

export const isRegistrationRoute = (pathname: string) => {
  return (
    pathname.includes('/register') ||
    pathname.includes('/_error') ||
    pathname.includes('/register/verification/phone') ||
    pathname.includes('/register/verification/email') ||
    pathname.includes('/register/create-profile')
  );
};

// routes where you are not supposed to be when you are authenticated
export const isUnauthenticatedRoute = (pathname: string) => {
  return (
    pathname.includes('/login') ||
    pathname.includes('/register') ||
    pathname.includes('/account/password-reset') ||
    pathname.includes('/invitations')
  );
};

// routes that don't require authentication.
export const isUnRestrictedRoute = (pathname: string) => {
  return (
    pathname.includes('/_error') ||
    isUnauthenticatedRoute(pathname) ||
    pathname.includes('/articles/') ||
    pathname.includes('/alerts/feedback')
  );
};
