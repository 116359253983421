import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import type { BinderTagRequest } from 'src/api-sdk';
import { useAPIContext } from 'src/util/context/APIContext';
import type {
  BinderFilters,
  BindersFilters,
} from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';

type UseBindersParams = {
  enabled?: boolean;
  searchQuery?: string;
};

type ResultHooks = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useBindersList = (
  filters: BindersFilters = {},
  { enabled = true }: UseBindersParams = {}
) => {
  const { API } = useAPIContext();

  return useInfiniteQuery(
    [QUERIES.BINDERS, { filters }],
    async ({ pageParam }) => {
      return (
        await API?.bindersAPI.bindersList({
          query: filters.searchQuery ?? '',
          nextKey: pageParam,
          sorting: filters.sorting ?? 'alphabetical',
          sortingOrder: filters.sortingOrder ?? 'ascending',
          ownedBinders: filters.ownedBinders ?? false,
        })
      )?.data;
    },
    {
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
      enabled,
    }
  );
};

type AddOrRemoveToBinderParams = {
  binderId: string;
  articleId: string | null;
  complianceEntityResultId: string | null;
  tags?: BinderTagRequest[];
  note?: string;
};

type RemoveFromBinderParams = {
  binderId: string;
  binderItemId: string;
};

export const useAddToBinder = ({ onError, onSuccess }: ResultHooks = {}) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({
      articleId,
      complianceEntityResultId,
      binderId,
      tags = [],
      note,
    }: AddOrRemoveToBinderParams) => {
      const binderItemData = (
        await API?.bindersAPI.bindersItemsCreate({
          binderId,
          binderItemRequest: {
            tags,
            article: articleId ? { external_id: articleId } : null,
            // @ts-ignore
            compliance_entity_result: complianceEntityResultId
              ? { external_id: complianceEntityResultId }
              : null,
          },
        })
      )?.data;
      if (note && binderItemData) {
        await API?.bindersAPI.bindersItemsNotesCreate({
          binderId: binderId,
          binderItemId: binderItemData.external_id,
          binderItemNoteRequest: {
            text: note,
          },
        });
      }
      return binderItemData;
    },
    {
      onSuccess,
      onError,
    }
  );
};

export const useRemoveFromBinder = ({ onError, onSuccess }: ResultHooks) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({ binderItemId, binderId }: RemoveFromBinderParams) => {
      return (
        await API?.bindersAPI.bindersItemsDestroy({
          binderId,
          binderItemId,
        })
      )?.data;
    },
    { onSuccess, onError }
  );
};

type UseBinderDataParams = {
  binderId: string;
};

export const useBinderData = (
  { binderId }: UseBinderDataParams,
  { onError, onSuccess }: ResultHooks = {}
) => {
  const { API } = useAPIContext();

  return useQuery(
    [QUERIES.BINDER_DATA, binderId],
    async () => {
      return (
        await API?.bindersAPI.bindersRetrieve({
          binderId,
        })
      )?.data;
    },
    {
      onSuccess,
      onError,
    }
  );
};

type UseBinderItemsParams = {
  binderId: string;
  searchQuery?: string;
  enabled?: boolean;
  sorting: BinderFilters['sorting'];
  tags?: string[];
};

export const useBinderItems = ({
  binderId,
  enabled = true,
  searchQuery,
  sorting,
  tags = [],
}: UseBinderItemsParams) => {
  const { API } = useAPIContext();

  return useInfiniteQuery(
    [QUERIES.BINDER_DATA, binderId, searchQuery, sorting, tags],
    async ({ pageParam }) => {
      return (
        await API?.bindersAPI.bindersItemsList({
          binderId,
          sorting,
          query: searchQuery,
          nextKey: pageParam,
          tags,
        })
      )?.data;
    },
    {
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
      enabled,
    }
  );
};

export const useRemoveBinder = ({ onError, onSuccess }: ResultHooks = {}) => {
  const { API } = useAPIContext();

  return useMutation(
    async (binderId: string) => {
      return (
        await API?.bindersAPI.bindersDestroy({
          binderId,
        })
      )?.data;
    },
    {
      onSuccess,
      onError,
    }
  );
};

type UpdateBinderParams = {
  binderId: string;
  name: string;
};

export const useUpdateBinder = ({ onError, onSuccess }: ResultHooks = {}) => {
  const { API } = useAPIContext();

  return useMutation(
    async ({ binderId, name }: UpdateBinderParams) => {
      return (
        await API?.bindersAPI.bindersUpdate({
          binderId,
          binderRequest: {
            name,
          },
        })
      )?.data;
    },
    {
      onSuccess,
      onError,
    }
  );
};

type UseBindersTagsParams = {
  binderId: string;
  query?: string;
  enabled?: boolean;
};

export const useBinderTags = ({
  binderId,
  enabled = true,
  query,
}: UseBindersTagsParams) => {
  const { API } = useAPIContext();

  return useInfiniteQuery(
    [QUERIES.BINDER_TAGS, binderId, query],
    async ({ pageParam }) => {
      return (
        await API?.bindersAPI.bindersTagsList({
          sorting: 'created_at',
          binderId,
          sortingOrder: 'descending',
          nextKey: pageParam,
          query,
        })
      )?.data;
    },
    {
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
      cacheTime: 0,
      staleTime: 0,
      enabled,
    }
  );
};

type UseBinderItemParams = {
  binderId: string;
  binderItemId: string;
};

export const useBinderItem = (
  { binderId, binderItemId }: UseBinderItemParams,
  { enabled = false }: { enabled?: boolean } = {}
) => {
  const { API } = useAPIContext();

  return useQuery(
    [QUERIES.BINDER_ITEM, binderId, binderItemId],
    async () => {
      return (
        await API?.bindersAPI.bindersItemsRetrieve({
          binderId,
          binderItemId,
        })
      )?.data;
    },
    { enabled }
  );
};

type UseBinderItemNotesParams = {
  binderId: string;
  binderItemId: string;
  enabled?: boolean;
};

export const useBinderItemNotes = ({
  binderId,
  binderItemId,
  enabled = true,
}: UseBinderItemNotesParams) => {
  const { API } = useAPIContext();

  return useInfiniteQuery(
    [QUERIES.BINDER_ITEM_NOTES, binderId, binderItemId],
    async ({ pageParam }) => {
      return (
        await API?.bindersAPI.bindersItemsNotesList({
          binderId,
          binderItemId,
          nextKey: pageParam,
          sorting: 'created_at',
          sortingOrder: 'descending',
        })
      )?.data;
    },
    {
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
      enabled,
    }
  );
};

type UsePostBinderNoteParams = {
  binderId: string;
  binderItemId: string;
};

export const usePostBinderNote = (
  { binderId, binderItemId }: UsePostBinderNoteParams,
  { onError, onSuccess }: ResultHooks = {}
) => {
  const { API } = useAPIContext();

  return useMutation(
    async (note: string) => {
      if (binderItemId) {
        return (
          await API?.bindersAPI.bindersItemsNotesCreate({
            binderId,
            binderItemId,
            binderItemNoteRequest: {
              text: note,
            },
          })
        )?.data;
      }
      return (
        await API?.bindersAPI.bindersNotesCreate({
          binderId,
          binderItemNoteRequest: {
            text: note,
          },
        })
      )?.data;
    },
    {
      onSuccess,
      onError,
    }
  );
};

export const useBinderItemTagsUpdate = (
  { binderId, binderItemId }: UsePostBinderNoteParams,
  { onError, onSuccess }: ResultHooks = {}
) => {
  const { API } = useAPIContext();

  return useMutation(
    async (tags: string[]) => {
      return await API?.bindersAPI.bindersItemsPartialUpdate({
        binderId,
        binderItemId,
        patchedBinderItemRequest: {
          tags: tags.map((t) => ({ text: t })),
        },
      });
    },
    {
      onSuccess,
      onError,
    }
  );
};

type UseBinderNotesParams = {
  binderId: string;
};

type UseBinderNotesOptions = {
  enabled?: boolean;
} & ResultHooks;

export const useBinderNotes = (
  { binderId }: UseBinderNotesParams,
  { enabled = true }: UseBinderNotesOptions = {}
) => {
  const { API } = useAPIContext();

  return useInfiniteQuery(
    [QUERIES.BINDER_NOTES, binderId],
    async ({ pageParam }) => {
      return (
        await API?.bindersAPI.bindersNotesList({
          binderId,
          nextKey: pageParam ?? undefined,
        })
      )?.data;
    },
    {
      getNextPageParam: (lastPage) => lastPage?.next_key ?? undefined,
      keepPreviousData: true,
      enabled,
    }
  );
};
